// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/_css-loader@3.6.0@css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/_css-loader@3.6.0@css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/logo.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "[data-v-888544fe] .logo-bg{display:block;width:20px;height:20px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");margin:0 auto}", ""]);
// Exports
module.exports = exports;
