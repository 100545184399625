<template>
<div>
    <div class="pull-left">
        <el-form style="float:left" label-width="80px">
            <el-form-item label="活动名称">
                <el-input v-model="pageParams.LabelName"></el-input>
            </el-form-item>
        </el-form>
        <el-button class="ml16" type="primary" icon="el-icon-search" @click="getList">搜索</el-button>
    </div>
    <div class="pull-right">
        <el-button type="primary" icon="el-icon-plus" @click="showLabelModal" plain>新增</el-button>
    </div>
    <el-table :data="data.rows" v-loading="tableLoading" element-loading-text="拼命加载中" element-loading-spinner="logo-bg" element-loading-background="rgba(0, 0, 0, 0.8)" border style="width: 100%">
        <el-table-column prop="LabelName" label="标签名称" width="180">
        </el-table-column>
        <el-table-column prop="name" label="标签颜色">
            <template slot-scope="scope">
                <i :style="{background:scope.row.LabelColour}" class="el-button el-button--mini is-circle"></i>
            </template>
        </el-table-column>
        <el-table-column prop="LabelType" label="标签类别">
            <template slot-scope="{row}">
                {{row.LabelType | label}}
            </template>
        </el-table-column>
        <el-table-column width="180" label="操作">
            <template slot-scope="{row}">
                <el-button type="primary" size="mini" @click="showLabelModal(row)" icon="el-icon-edit" circle></el-button>
                <el-popover placement="top-end" width="160" v-model="row.visible">
                    <p>确定删除这个标签吗？</p>
                    <div style="text-align: right; margin: 0">
                        <el-button size="mini" type="text" @click="row.visible = false">取消</el-button>
                        <el-button type="primary" size="mini" @click="deleteLabel(row)">确定</el-button>
                    </div>
                    <el-button class="ml16" type="danger" size="mini" icon="el-icon-delete" slot="reference" circle></el-button>
                </el-popover>

            </template>
        </el-table-column>
    </el-table>
    <el-pagination @size-change="sizeChange" @current-change="pageChange" :current-page="pageParams.page" layout="sizes,total,pager" :page-size="pageParams.rows" :total="data.total">
    </el-pagination>
    <el-dialog :title="modalTitle" :visible.sync="dialogVisible" width="30%">
        <el-form :model="labelModalData" :rules="rules" ref="labelForm" label-width="120px" :label-position="'right'" size="mini">
            <el-form-item label="标签名称" prop="LabelName">
                <el-input v-model="labelModalData.LabelName" auto-complete="off"></el-input>
            </el-form-item>
            <el-form-item label="标签颜色" prop="LabelColour">
                <el-color-picker v-model="labelModalData.LabelColour"></el-color-picker>
            </el-form-item>
            <el-form-item label="标签类别">
                <el-radio-group v-model="labelModalData.LabelType">
                    <el-radio :label="1">粉丝标签</el-radio>
                    <el-radio :label="2">会员标签</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="备注信息">
                <el-input v-model="labelModalData.Remark" auto-complete="off"></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitForm">确 定</el-button>
        </span>
    </el-dialog>
</div>
</template>

<script>
import api from "./api";
export default {
    data() {
        return {
            modalTitle: '',
            message: "info",
            dialogVisible: false,
            tableLoading: false,
            rules: {
                LabelName: [{
                        required: true,
                        message: "请输入标签名称",
                        trigger: "change"
                    },
                    {
                        min: 2,
                        max: 15,
                        message: "长度在 2 到 15 个字符",
                        trigger: "change"
                    }
                ],
                LabelColour: [{
                    required: true,
                    message: "请选择标签颜色",
                    trigger: "blur"
                }]
            },
            labelModalData: {
                LabelName: "",
                LabelColour: "",
                LabelType: ""
            },
            pageParams: {
                LabelName: "",
                page: 1,
                rows: 15
            },
            data: {
                rows: [],
                total: 19
            }
        };
    },
    methods: {
        deleteLabel(row) {
            row.visible = false;
            api.deleteLabel({
                ID: row.tb_Label_ID
            }).then(re => this.getList(), err => console.log(err));
        },
        submitForm() {
            this.$refs.labelForm.validate(valid => {
                if (valid) {
                    this.dialogVisible = false;
                    this.labelModalData.tb_Label_ID ? api
                        .updateLabel(this.labelModalData)
                        .then(res => this.getList(), err => console.log(err)) :
                        api
                        .addLabel(this.labelModalData)
                        .then(res => this.getList(), err => console.log(err));
                } else {
                    return false;
                }
            });
        },
        showLabelModal(data) {
            this.modalTitle = `${data.tb_Label_ID ? '编辑' :'新增'}标签`;
            let tempdata = JSON.parse(JSON.stringify(data || {}));
            this.dialogVisible = true;
            this.labelModalData.LabelName = tempdata.LabelName;
            this.labelModalData.LabelColour = tempdata.LabelColour;
            this.labelModalData.LabelType = tempdata.LabelType;
            this.labelModalData.tb_Label_ID = tempdata.tb_Label_ID;
        },
        sizeChange(size) {
            this.pageParams.rows = size;
            this.getList();
        },
        pageChange(page) {
            this.pageParams.page = page;
            this.getList();
        },
        getList(isSearch) {
            isSearch && (this.pageParams.page = 1);
            this.tableLoading = true;
            api.getList(this.pageParams).then(
                res => {
                    this.tableLoading = false;
                    this.data.rows = res.rows;
                    this.data.total = res.total;
                },
                err => {
                    this.tableLoading = false;
                    console.log(err)
                }
            );
        }
    },
    created() {
        this.getList();
    },
    filters: {
        label(LabelType) {
            const labelTypeList = {
                1: '粉丝标签',
                2: '会员标签'
            }
            return labelTypeList[LabelType] || '';
        }
    }
};
</script>

<style scoped>
/deep/ .logo-bg {
    display: block;
    width: 20px;
    height: 20px;
    background: url('../../../assets/logo.png');
    margin: 0 auto;
}
</style>
